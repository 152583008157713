import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Grid, Link, Container } from '@mui/material';
import { Facebook, Instagram, WhatsApp } from '@mui/icons-material';
import { Youtube } from 'lucide-react';

const styles = {
  footer: {
    bgcolor: '#0a2240',
    color: 'white',
    py: { xs: -8, sm: 4 },  // Reduced padding for xs screens (py: 3)
    mt: 'auto',
  },
  contactTitle: {
    fontWeight: 'bold',
    
    mb: 2,
    color: '#4a90e2',
    display: 'flex',
    alignItems: 'center',
  },
  contactTitleSocial: {
    fontWeight: 'bold',
    mb: 2,
    color: '#4a90e2',
    display: 'flex',
    alignItems: 'center',
    marginRight: 20,
  },
  contactInfo: {
    mb: 1,
  },
  socialLink: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    '&:hover': { color: '#4a90e2' },
  },
  socialLink1: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    color: 'white',
    '&:hover': { color: '#4a90e2' },
  },
  emailInput: {
    mb: 2,
    bgcolor: 'white',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: '#4a90e2',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4a90e2',
      },
    },
  },
  subscribeButton: {
    py: 1,
    px: 2,
    bgcolor: '#4a90e2',
    '&:hover': {
      bgcolor: '#3a78c2',
    },
  },
  divider: {
    my: 4,
    bgcolor: 'rgba(255, 255, 255, 0.2)',
  },
  policyLink: {
    color: 'white',
    '&:hover': { color: '#4a90e2' },
  },
  logo: {
    width: '100px', // Adjust the size of the logo
    height: '100px',
    marginRight: '8px',
    alignItems: 'center',
  },
};

const Footer = () => {
  const [email, setEmail] = useState('');

  const handleSubscribe = (e) => {
    e.preventDefault();
    console.log(`Subscribed with email: ${email}`);
    setEmail('');
  };

  return (
    <Box sx={styles.footer}>
      <Container maxWidth="lg" sx={{ padding: { xs: 6, sm: 1 } }}>
        <Grid container spacing={4}>
          {/* Contact Information */}
          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <img
                src={require("../../../Assets/footerlogo.png")}
                alt="Sharon Writers Logo"
                style={styles.logo}
              />
              <Box>
                <Typography variant="h5" sx={styles.contactTitle}>
                  Sharon Writers Forum
                </Typography>
                <Typography sx={styles.contactInfo}>
                  A community dedicated to inspiring and connecting writers and readers.
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Contact Info Section */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={styles.contactTitle}>Contact Us</Typography>
            <Typography sx={styles.contactInfo}>Sharon Writers</Typography>
            <Typography sx={styles.contactInfo}>Email: info@sharonwriters.com</Typography>
            <Typography>Phone: +91 469 260 7979</Typography>
          </Grid>

         




          {/* Social Media Links */}
          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              {/* Connect With Us Heading */}
              <Typography variant="h6" sx={styles.contactTitleSocial}>Connect With Us</Typography>
              
              {/* Social Media Links */}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Link href="https://www.facebook.com/share/19YivZeHEB/" target="_blank" sx={styles.socialLink}>
                  <Facebook sx={{ mr: 1 }} /> Facebook
                </Link>
                <Link href="https://youtube.com" target="_blank" sx={styles.socialLink1}>
                  <Youtube sx={{ mr: 1 }} /> Youtube
                </Link>
                <Link href="https://chat.whatsapp.com/LI6j7ICA29N16NTXTbjuyB" target="_blank" sx={styles.socialLink}>
                  <WhatsApp sx={{ mr: 1 }} /> WhatsApp
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
