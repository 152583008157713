import React ,{useEffect} from 'react';
import { Container, Typography, Box, Button, Card, Grid } from '@mui/material';
import qr from '../../../Assets/qr.png';
// Dummy QR code image (you can replace this with any QR code generator)


function Payment() {

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);


  return (
    <Container maxWidth="lg" style={{ marginTop: '40px' }}>
      
      <Card sx={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f9f9f9', borderRadius: '12px', boxShadow: 3, p: 3 }}>
        <Grid container spacing={3} alignItems="center">
          {/* QR Code Section */}
          <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={qr}
              alt="Dummy QR Code"
              style={{ width: '600px',  }}
            />
          </Grid>

          {/* Payment Details Section */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" component="h2" gutterBottom>
            Amount: <span style={{ color: '#AD62AA',fontSize:'1.8rem' }}>₹ 260</span>
            </Typography>
            <Typography variant="body1" paragraph>
              Please scan the QR code to complete your payment.
            </Typography>

           
          </Grid>
        </Grid>

        

      </Card>

      
    </Container>
  );
}

export default Payment;
