import React, { useState } from 'react';
import { Box, Button, Card, TextField, Typography, Grid, Container, InputLabel, FormControl, FormHelperText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { postAuthor } from '../../Services/AdminServices'; // Assuming the API service for creating an author is in this file
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer'; // Import ResponsiveDrawer
import toast, { Toaster } from 'react-hot-toast';

// Styled components for consistent look and feel
const card = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  marginBottom: 20,
  width: '70%',
  '@media (max-width: 1200px)': {
    width: '70%', // Adjust width for desktop
  },
  '@media (max-width: 992px)': {
    width: '100%', // Adjust width for tablets
  },
  '@media (max-width: 768px)': {
    width: '100%', // Adjust width for Mobiles
  },
}));

const NewAuthor = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [photoError, setPhotoError] = useState(false);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    let error = false;
    if (!name) {
      setNameError(true);
      error = true;
    }
    if (!photo) {
      setPhotoError(true);
      error = true;
    }

    if (error) return;

    setLoading(true);

    const formData = new FormData();
    formData.append('name', name);
    formData.append('bio', bio);
    formData.append('photo', photo);

    try {
      const response = await postAuthor(formData);
      toast.success(response?.data?.message || 'Author created successfully');
      setTimeout(() => {
        navigate('/admin/authors');  // Navigate to the authors list after success
      }, 2000);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Error creating author');
    } finally {
      setLoading(false);
    }
  };

  // Handle photo preview
  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPhoto(file);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ padding: 0 }}>
      <Grid container spacing={2}>
        {/* Sidebar (Responsive Drawer) */}
        <Grid item xs={12} lg={2}>
          <ResponsiveDrawer />
        </Grid>

        {/* Main Content */}
        <Grid item xs={12} lg={10} sx={{ mt: 12 }}>
          <Box sx={{ mb: 2 }}>
            <Grid container alignItems="center">
              <Grid item xs >
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginLeft: 3 }}>
                  Add New Author
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* Author Form */}
          <Box component={card}>
            <Box className="section-new-author-add-information-header-flex">
              <div className='new-author-add-information-icon'></div>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              
              </Typography>
            </Box>

            <Box className="new-author-padding">
              {/* Input for Author Name */}
              <TextField
                error={nameError}
                id="author-name"
                label="Author Name"
                placeholder="Enter Author Name"
                variant="outlined"
                fullWidth={true}
                required={true}
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{ mb: 5 }}
                helperText={nameError && "Please enter the name"}
              />

              {/* Input for Author Bio */}
              <TextField
                id="author-bio"
                label="Bio"
                placeholder="Enter Author Bio"
                variant="outlined"
                fullWidth={true}
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                sx={{ mb: 5 }}
                multiline
                rows={4}
              />

              {/* Input for Author Photo */}
              <FormControl fullWidth sx={{ mb: 5 }} required={true} error={photoError}>
                <InputLabel id="photo-upload-label"></InputLabel>
                <input
                  type="file"
                  id="photo-upload"
                  accept="image/*"
                  onChange={handlePhotoChange}
                  style={{ display: 'none' }}
                />
                <label htmlFor="photo-upload">
                  <Button
                    variant="outlined"
                    component="span"
                    fullWidth
                    sx={{ textAlign: 'center' }}
                  >
                    {photo ? 'Change Photo' : 'Upload Photo'}
                  </Button>
                </label>
                {photo && (
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                    <img
                      src={URL.createObjectURL(photo)}
                      alt="Selected"
                      style={{ maxWidth: '200px', maxHeight: '200px', objectFit: 'cover' }}
                    />
                  </Box>
                )}
              </FormControl>
            </Box>

            <Box className="new-author-padding">
              <Button
                variant="contained"
                style={{ backgroundColor: "#A56266", color: "white", borderRadius: 10, fontSize: 15 }}
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save Author'}
              </Button>
            </Box>
          </Box>

          {/* Toast Notifications */}
          <Toaster
            position="top-center"
            reverseOrder={false}
            toastOptions={{
              success: {
                style: {
                  background: 'rgb(46, 125, 50)',
                  color: 'white',
                },
                iconTheme: {
                  primary: 'rgb(46, 125, 50)',
                  secondary: 'white',
                },
              },
              error: {
                style: {
                  background: 'rgb(211, 47, 47)',
                  color: 'white',
                },
                iconTheme: {
                  primary: 'rgb(211, 47, 47)',
                  secondary: 'white',
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewAuthor;
