import React, { useState } from 'react';
import './MagazineStyles.css';
import { styled } from '@mui/system';
import aboutdetail from '../../../Assets/wallpaper.jpg';
import { Box, Typography } from '@mui/material';
import mag1 from '../../../Assets/art5.jpeg';
import mag2 from '../../../Assets/art6.jpeg';
import mag3 from '../../../Assets/art7.jpeg';
import mag4 from '../../../Assets/art8.jpeg';
import slide from '../../../Assets/pubs.jpeg';
import { useNavigate } from 'react-router-dom';
const MonthlyMagazine = () => {

  const navigate = useNavigate();
  const [hoveredCard, setHoveredCard] = useState(null);

  const magazines = [
    {
      id: 1,
      month: 'January 2024',
      coverImage: mag1,
      pdfUrl: '/path/to/january-magazine.pdf',
    },
    {
      id: 2,
      month: 'February 2024',
      coverImage: mag2,
      pdfUrl: '/path/to/february-magazine.pdf',
    },
    {
      id: 3,
      month: 'March 2024',
      coverImage: mag3,
      pdfUrl: '/path/to/march-magazine.pdf',
    },
    {
      id: 4,
      month: 'April 2024',
      coverImage: mag4,
      pdfUrl: '/path/to/april-magazine.pdf',
    },
  ];

  const HeroSection = styled(Box)(({ theme }) => ({
    position: 'relative',
    backgroundImage: `url(${aboutdetail})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    textAlign: 'center',
    padding: theme.spacing(8, 0),
  }));

  const HeroContent = styled(Box)(({ theme }) => ({
    zIndex: 1,
    maxWidth: '800px',
    padding: theme.spacing(4),
  }));

  
  const handleDownload = () => {
    navigate('/');
  };
  return (
    <Box sx={{ mt: { xs: 0, lg: 3 } }}>
       <HeroSection>
        <HeroContent>
          <Typography variant="h4" sx={{ fontWeight: 600, color: '#AD62AA', fontSize: { xs: '1.5rem', md: '2.5rem' }, mt: 2 }}>
          Spandanam
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem', color: '#AD62AA' }}>
         A Monthly Journey of Knowledge and Spiritual Awakening
          </Typography>
        </HeroContent>
      </HeroSection>
      <div className="magazine-grid">
        {magazines.map((magazine) => (
          <div
            key={magazine.id}
            className={`magazine-card ${hoveredCard === magazine.id ? 'hovered' : ''}`}
            onMouseEnter={() => setHoveredCard(magazine.id)}
            onMouseLeave={() => setHoveredCard(null)}
          >
            <div className="card-inner">
              <div className="card-front">
                <div className="card-header">
                  <h2>{magazine.month}</h2>
                  <span>{magazine.year}</span>
                </div>
                <div className="card-image">
                  <img
                    src={magazine.coverImage}
                    alt={`${magazine.month} Magazine Cover`}
                  />
                </div>
              </div>
              <div className="card-back">
                <button
                  className="download-btn"
                  onClick={() => handleDownload(magazine.pdfUrl)}
                >
                  Download PDF
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Box sx={{ mb:-2.5, ml: -3,mt:2 }}>
    
      <img 
        src={slide} 
        alt="Magazine Slide" 
        style={{ width: '100%', height: '100%',  }} // Optional styles
      />
   
      </Box>

    </Box>
  );
};

export default MonthlyMagazine;
