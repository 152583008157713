import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Button,  } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { getFilteredNewsData } from '../../../Services/AdminServices';
import parse from 'html-react-parser';
import { saveToLocalStorage } from '../../../Helper/helper';
import bookcover from '../../../Assets/homepub.jpeg';
import './SpiritualNews.css';

// Helper function to format the slug for the news detail URL
const formatNewsDetailUrl = (slug) => {
  return `/news-articles/getNewsBySlug/${encodeURIComponent(slug)}`;
};

const extractImageFromContent = (htmlContent) => {
  if (!htmlContent) return '/DefaultImages/news_default_img.jpeg';
  
  let imageUrl = '/DefaultImages/news_default_img.jpeg';
  parse(htmlContent, {
    replace: (domNode) => {
      if (domNode.name === 'img' && domNode.attribs?.src) {
        imageUrl = domNode.attribs.src;
      }
      return null;
    },
  });
  
  return imageUrl;
};

// New utility function to strip images and get text content
const getFilteredContent = (htmlContent) => {
  if (!htmlContent) return '';

  let textContent = '';
  const parsed = parse(htmlContent, {
    replace: (domNode) => {
      if (domNode.type === 'text') {
        textContent += domNode.data + ' ';
      }
      // Skip img tags
      if (domNode.name === 'img') {
        return null;
      }
      return domNode;
    },
  });

  // Clean up and limit the text content
  return textContent
    .trim()
    .replace(/\s+/g, ' ') // Replace multiple spaces with single space
    .slice(0, 150) + '...'; // Limit to 150 characters with ellipsis
};

function SpiritualNews() {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        setLoading(true);
        const data = { 
          categoryName: 'spiritual', 
          type: 'news',
          count: 3
        };
        const response = await getFilteredNewsData(data);

        if (response?.data?.success && Array.isArray(response.data.latestNews)) {
          setNewsData(response.data.latestNews);
        } else {
          setError('No spiritual news found.');
        }
      } catch (err) {
        setError('Failed to fetch spiritual news: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography>Loading spiritual news...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <div className="container">
      <Grid
        container
        spacing={1}
        sx={{
          padding: {
            xs: '5%',
            sm: '5%',
            lg: '24px'
          }
        }}
      >
        {/* Left Side Grid */}
        <Grid item xs={12} sm={8} md={8}>
          <Grid container justifyContent="space-between" alignItems="center" className="header-container">
            <Button
              variant="contained"
              sx={{ fontWeight: 'bold',  mt: -1, ml: { xs: 2, sm: 0 }, backgroundColor: '#AD62AA', '&:hover': { backgroundColor: '#04049a' } }}
            >
              Spiritual News
            </Button>

            {/* <Button 
              variant="text" 
              sx={{ color: '#C37028', fontWeight: 'bold' }}
              onClick={() => navigate('/news/spiritual')}
            >
              See All
            </Button> */}
          </Grid>
          {/* <Divider /> */}
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {/* Spiritual News Items */}
            {newsData.map((news, index) => {
              const imageUrl = extractImageFromContent(news.content);
              const filteredContent = getFilteredContent(news.content);
              const newsDetailUrl = formatNewsDetailUrl(news.slug);

              return (
                <Grid item xs={12} sm={4} key={index}>
                  <Box className="news-item" sx={{ height: '100%' }}>
                    {/* Image */}
                    <Box
                      component="img"
                      src={imageUrl}
                      alt={`News ${index + 1}`}
                      className="news-thumbnail"
                      sx={{
                        width: '100%',
                        height: 200,
                        objectFit: 'cover',
                        borderRadius: '5px',
                        cursor: 'pointer',
                      
                      }}
                      onClick={() => {
                        saveToLocalStorage(window.location.pathname, window.scrollY);
                        navigate(newsDetailUrl);
                      }}
                    />
                    
                    {/* News Title */}
                    <Link 
                      to={newsDetailUrl} 
                      className="news-link"
                      style={{ textDecoration: 'none' }}
                      onClick={() => saveToLocalStorage(window.location.pathname, window.scrollY)}
                    >
                      <Typography 
                        variant="h6" 
                        sx={{ 
                          textAlign: 'left', 
                          fontWeight: 'bold', 
                          color: 'Black',
                          mt: 1,
                          mb: 1,
                          ml: { xs: 4, sm: 0 },
                          fontSize: {xs:'1rem',lg:'1rem'},
                          fontWeight:'bold',
                          minHeight: '2.7em',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {news.title}
                      </Typography>
                    </Link>

                    {/* News Content Snippet */}
                    <Typography 
                      variant="body2" 
                      sx={{
                        color: 'text.secondary',
                        fontSize: {xs:'0.9rem',lg:'0.9rem'},
                        lineHeight: 1.5,
                        overflow: 'hidden',
                        ml: { xs: 4, sm: 0 },
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {filteredContent}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        {/* Right Side Grid - Book Promo */}
        <Grid item xs={12} sm={4} md={4}>
          <Box className="book-promo">
            <Box
              component="img"
              src={bookcover}
              alt="Book Cover"
              sx={{ 
                width: '100%', 
                height: 'auto', 
                transition: 'transform 0.3s',
                display: 'block',
                borderRadius: '8px',
                
              }}
            />
            
            <Box className="book-overlay">
              <Typography variant="h5" sx={{ 
                color: '#FFF', 
                textAlign: 'center', 
                fontWeight: 'bold', 
                marginBottom: 1,
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'
              }}>
                Discover Your Next Read!
              </Typography>
              <Typography variant="body2" sx={{ 
                color: '#FFF', 
                textAlign: 'center', 
                marginBottom: 2, 
                maxWidth: '80%'
              }}>
                Dive into the captivating world of our latest publication.
              </Typography>
              <Button
                variant="contained"
                sx={{ 
                  backgroundColor: '#AD62AA', 
                  '&:hover': { 
                    backgroundColor: '#E64A19',
                    transform: 'scale(1.05)'
                  }, 
                  fontWeight: 'bold', 
                  paddingX: 3,
                  borderRadius: '16px',
                  textTransform: 'none',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.2s, background-color 0.2s'
                }}
                onClick={() => navigate('/publications')}
              >
                Explore More
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default SpiritualNews;
