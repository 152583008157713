import React from 'react';
import { Container, Grid, Typography, Box, Button, Card, CardContent, CardMedia } from '@mui/material';
import { styled } from '@mui/system';
import aboutdetail from '../../../Assets/wallpaper.jpg';
import class1 from '../../../Assets/class1.jpeg';
import class2 from '../../../Assets/class2.jpg';
import class3 from '../../../Assets/class3.jpg';
import './Courses.css';
import Magazine from '../HomePage/Magazine';

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${aboutdetail})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  padding: theme.spacing(8, 0),
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const HeroContent = styled(Box)(({ theme }) => ({
  zIndex: 1,
  maxWidth: '800px',
  padding: theme.spacing(4),
}));





// Sample course data with images
const courses = [
  {
    courseName: "LEADERSHIP",
    teacherName: "Rev. John Doe",
    dateTime: "September 20, 2024 - 10:00 AM",
    googleClassroomLink: "https://classroom.google.com",
    image: class1,
  },
  {
    courseName: "BIBLICAL STUDIES",
    teacherName: "Sister Mary Smith",
    dateTime: "September 22, 2024 - 2:00 PM",
    googleClassroomLink: "https://classroom.google.com",
    image: class2,
  },
  {
    courseName: "SPIRITUAL WARFARE",
    teacherName: "James London",
    dateTime: "September 22, 2024 - 2:00 PM",
    googleClassroomLink: "https://classroom.google.com",
    image: class3,
  },
];




function Courses() {
  return (

    <div>
    <Box sx={{ mt: { xs: 0, lg: 3 } }}>
{/* Hero Section */}
<HeroSection>
        <HeroContent>
          <Typography variant="h4" sx={{ fontWeight: 600, color: '#AD62AA', fontSize: { xs: '1.5rem', md: '2.5rem' }, mt: 2 }}>
           Courses
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem', color: '#AD62AA' }}>
          Enhance Your spiritual Growth with Our Comprehensive Journalism Course
          </Typography>
        </HeroContent>
      </HeroSection>

      <Container 
  maxWidth="lg" 
  className="courses-container"
  sx={{
    paddingLeft: { xs: '40px', sm: '24px' }, 
    paddingRight: { xs: '16px', sm: '24px' }, 
    paddingTop: { xs: '20px', sm: '24px' }, 
    paddingBottom: { xs: '30px', sm: '24px' } 
  }}
>
  <Grid container spacing={3}>
    {courses.map((course, index) => (
      <Grid item xs={12} sm={6} md={4} key={index}>
        <Card className="course-card">
          <CardMedia
            component="img"
            image={course.image}
            alt={`Image for ${course.courseName}`}
            className="course-image"
          />
          <CardContent>
            <Typography variant="h5" component="h2" className="course-name">
              {course.courseName}
            </Typography>
            <Typography variant="subtitle1" className="course-teacher">
              By: {course.teacherName}
            </Typography>
            <Typography variant="body1" paragraph className="course-date">
              Date & Time: {course.dateTime}
            </Typography>
            <Box mt={2}>
              <Button
                variant="contained"
                className="join-button"
                sx={{
                  backgroundColor: '#0a2240', // Set the button color
                  color: 'white',
                }}
              >
               Join Class
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
</Container>
 </Box>

<Box sx={{ mb: 0, ml: 0, mt: 2 }}>

<Magazine />

</Box>
</div>



  );
}

export default Courses;
