import React ,{useEffect} from 'react';
import { Grid, Typography, Box, Container, Card, CardContent, CardMedia,  } from '@mui/material';
import { styled } from '@mui/system';
import aboutdetail from '../../../Assets/wallpaper.jpg';
import mission from '../../../Assets/Team/about1.png';
import vision from'../../../Assets/Team/about2.png';
import book from '../../../Assets/Team/about3.png'

import Sam from '../../../Assets/Team/Sam T Mukhathala.png'
import vennikkulam  from'../../../Assets/Team/J.P VENNIKKULAM.png'
import aneesh from '../../../Assets/Team/ANEESH KOLLAMKODE.png';
import job from '../../../Assets/Team/K.J.JOB KALPATTA.png';
import jobis from '../../../Assets/Team/JOBIS JOSE.png'
import titus from '../../../Assets/Team/TITUS JOHNSON.png'; 
import godson from '../../../Assets/Team/GODSON .C. SUNNY.png';
import biju from '../../../Assets/Team/BIJU JOSEPH.png';
import abraham from '../../../Assets/Team/ABRAHAM MANDAMARUTHI.png'
import robin from '../../../Assets/Team/ROBINSON PAPPACHAN.png';
import johnson from '../../../Assets/Team/JOHNSON OOMMAN.png';
import sovi from '../../../Assets/Team/SOVI MATHEW.png';
import sajo from '../../../Assets/Team/SAJO THONIKUZHIYIL.png';
import praise from '../../../Assets/Team/PRAISE VARGHESE.png';
import benson from '../../../Assets/Team/BENSON DANIEL.png';
import jessy from '../../../Assets/Team/JESSY KOSHY.png';
import feba from '../../../Assets/Team/FEBA AJITH.png';
import blesson from '../../../Assets/Team/BLESSON  GEORGE.png';



const HeroSection = styled(Box)(({ theme }) => ({
    position: 'relative',
    
    backgroundImage: `url(${aboutdetail})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    textAlign: 'center',
    padding: theme.spacing(8, 0),
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  }));
  
  const HeroContent = styled(Box)(({ theme }) => ({
    zIndex: 1,
    maxWidth: '800px',
    padding: theme.spacing(4),
  }));

const Aboutus = () => {


    useEffect(() => {
        window.scrollTo(0, 0); 
      }, []);

    return (
        <Box sx={{ mt: { xs: 0, lg: 3 } }}>
      
             {/* Hero Section */}
             <HeroSection>
        <HeroContent>
          <Typography variant="h4" sx={{ fontWeight: 600, color: '#AD62AA', fontSize: { xs: '1.5rem', md: '2.5rem' }, mt: 2 }}>
          About Us
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem', color: '#AD62AA' }}>
         Let's connect ,share ideas,and grow together
          </Typography>
        </HeroContent>
      </HeroSection>
      <Box sx={{ ml: { xs: 1.5 } }}>
            <Container maxWidth="lg"  sx={{
    paddingLeft: { xs: '20%', sm: 0 }, 
    paddingRight: { xs: '5%', sm: 0 }, 
    paddingTop: { xs: '18%', sm: '5%' }, 
    paddingBottom: { xs: '40px', sm: '24px' } 
  }}>
               <Grid container spacing={8} alignItems="center">
                   
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" sx={{  fontWeight: 600,fontSize: { xs: '1.5rem', md: '2.5rem' } ,color: '#302c51', mb:1 ,mt:-6}}>
                            Our Mission
                        </Typography>
                        <Typography variant="body1" sx={{  fontSize: {xs:14,sm:18}, mb:-3, color: '#1b1b1b', lineHeight: 1.5, textAlign: 'justify' }}>
                        Inspire and encourage reading and writing among the members of our community and beyond.
Provide guidance in navigating modern writing trends, ensuring that our members approach writing with thoughtfulness, care, and a balanced perspective.
Support new and budding writers, helping them develop their skills and share their voices with the world.We believe in the power of the written word to inspire, challenge, and transform.
 
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box
                            component="img"
                            src={mission}
                            alt="Our Mission"
                            sx={{
                                mt:-4,
                                width: '100%',
                                height: 300,
                                objectFit: 'cover',
                                borderRadius: 4,
                                boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
                                mb:2,
                            }}
                        />
                    </Grid>

                </Grid>

            

                <Grid container spacing={8} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" sx={{  fontWeight: 600, fontSize: { xs: '1.5rem', md: '2.5rem' },color: '#302c51', mb:1 }}>
                             Our Purpose
                        </Typography>
                        <Typography variant="body1" sx={{  fontSize: {xs:14,sm:18}, mb: 1, color: '#1b1b1b', lineHeight: 1.5, textAlign: 'justify' }}>
                        Our objectives include encouraging reading and writing within the Sharon congregation, fostering a culture where these activities are valued as tools for personal growth, spiritual development, and creative expression. We strive to help our members explore their thoughts and emotions through writing, deepening their appreciation for literature. We aim to promote a balanced approach to current writing trends, guiding writers to navigate them thoughtfully while staying aligned with our community’s values. Our forum is dedicated to supporting budding writers by providing a nurturing space to develop their skills, share their work, and receive constructive feedback. We believe everyone has a unique story to tell, and we help our members find their voice. Additionally, we share insightful writings and treatises from the church council, offering spiritual guidance and practical advice for living fulfilling lives.

                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            component="img"
                            src={vision}
                            alt="Our Vision"
                            sx={{
                                mt:-8,
                                width: '100%',
                                height: 300,
                                objectFit: 'cover',
                                borderRadius: 4,
                                boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
                            }}
                        />
                    </Grid>
                </Grid>
            

<Grid container spacing={8} alignItems="center">
<Grid item xs={12} md={6}>
                        <Typography variant="h4" sx={{  fontWeight: 600,fontSize: { xs: '1.5rem', md: '2.5rem' }, color: '#302c51', mb:1 ,mt:1}}>
                            Our Vision
                        </Typography>
                        <Typography variant="body1" sx={{  fontSize: {xs:14,sm:18}, mb: 1, color: '#1b1b1b', lineHeight: 1.5, textAlign: 'justify' }}>
                        Our vision is to build a thriving community of writers who are not only skilled in their craft but also use their talents to make a positive impact on the world. We aim to inspire a new generation of writers who contribute meaningfully to society and the church. Through the power of the written word, we hope to empower individuals to express their thoughts, ideas, and stories, creating a culture of creativity, reflection, and faith.
 
                        </Typography>
                    </Grid>
    <Grid item xs={12} md={6}>
  <Box
    component="img"
    src={book}
    alt="Our Vision"
    sx={{
      mt: { xs: -7, md: 5 }, // No margin-top for mobile (xs), 5 for desktop (md and above)
      width: '100%',
      height: 300,
      objectFit: 'cover',
      borderRadius: 4,
      boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
    }}
  />
</Grid>

</Grid>

               

                
            </Container>


            <Box  sx={{
    paddingLeft: { xs: '11%', sm: '6%' }, 
    paddingRight: { xs: '5%', sm:'6%' }, 
    paddingTop: { xs: '1%', sm: '2%' }, 
    paddingBottom: { xs: '40px', sm: '24px' } 
  }}>
                    <Typography variant="h4" sx={{ fontWeight: 600, color: '#302c51',fontSize: { xs: '2rem', md: '2.5rem' }, mb: {xs:2,sm:3},mr:2, textAlign: 'center' }}>
                        Meet Our Team
                    </Typography>
                    <Grid container spacing={4}>
                        {[ 
                            { name: 'Sam T Mukhathala', role: 'Chairman', image: Sam },
                            { name: 'J.P Vennikkulam', role: 'Vice Chairman ', image: vennikkulam },
                            { name: 'Aneesh Kollamkode', role: 'General Secretary', image: aneesh },
                            { name: 'K.J Job Kalpatta', role: 'Joint Secretary', image: job },
                            { name: 'Jobis Jose', role: 'Treasurer', image: jobis },
                            { name: 'Titus Johnson', role: 'Literature Secretary', image: titus },
                            { name: 'Godson C Sunny', role: 'Media Secretary', image: godson },
                              { name: 'Blesson George', role: 'General Coordinator', image: blesson },
                            { name: 'Biju Joseph', role: 'Member', image: biju },
                            { name: 'Abraham Mandamaruthi', role: 'Member', image: abraham },
                            { name: 'Robinson Pappachan', role: 'Member ', image: robin },
                            { name: 'Johnson Oomman', role: 'Member', image: johnson },
                            { name: 'Sovi Mathew', role: 'Member', image: sovi },
                            { name: 'Sajo Thonnikuzhiyil', role: 'Member', image: sajo },
                            { name: 'Praise Varghese', role: 'Member', image: praise },
                            { name: 'Benson Daniel', role: 'Member', image: benson },
                            { name: 'Jessy Koshy', role: 'Member', image: jessy },
                            { name: 'Feba Ajith', role: 'Member', image: feba },
                            
                        ].map((member, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', borderRadius: 4, overflow: 'hidden', boxShadow: '0 10px 30px rgba(0,0,0,0.1)', transition: 'all 0.3s ease-in-out', '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 20px 40px rgba(0,0,0,0.2)' } }}>
                                <Box sx={{ p: 6 }}>
                                <CardMedia
                                component="img"
                                sx={{ mt: -2}}  
                                 height="auto"
                                  image={member.image}
                                  alt={member.name}
                                    />

                                    <CardContent sx={{ flexGrow: 1, textAlign: 'center', bgcolor: 'white', p: 2 }}>
                                        <Typography variant="h6" sx={{ fontFamily: "'Playfair Display', serif", fontWeight: 600, color: '#302c51', mb: 1 }}>
                                            {member.name}
                                        </Typography>
                                        <Typography variant="subtitle1" sx={{ fontFamily: "'Lato', sans-serif", color: '#d40032 ' }}>
                                            {member.role}
                                        </Typography>
                                    </CardContent>
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    {/* <Grid container spacing={4}>
                        {[ 
                            { name: 'Jane Doe', role: 'CEO', image: team1 },
                            
                        ].map((member, index) => (
                            <Grid item xs={12} sm={12} md={12} key={index}>
                                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                                    <CardMedia
                                        component="img"
                                        height="480"
                                        image={member.image}
                                        alt={member.name}
                                    />
                                    <CardContent sx={{ flexGrow: 1, textAlign: 'center', bgcolor: 'white', p: 3 }}>
                                        <Typography variant="h6" sx={{ fontFamily: "'Playfair Display', serif", fontWeight: 600, color: '#302c51', mb: 1 }}>
                                           
                                        </Typography>
                                        <Typography variant="subtitle1" sx={{ fontFamily: "'Lato', sans-serif", color: '#d40032 ' }}>
                                           
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid> */}
                </Box>
                </Box>
       
        </Box>
    );
};

export default Aboutus;