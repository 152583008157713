import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, CircularProgress, Select, MenuItem, FormControl, InputLabel, Button, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { getFilteredNewsData, getAuthorsById } from '../../../Services/AdminServices';
import parse from 'html-react-parser';
import { styled } from '@mui/system';
import slide from '../../../Assets/pubs.jpeg';
import Magazine from '../HomePage/Magazine';
// Helper Functions
const formatNewsDetailUrl = (slug) => {
  return `/news-articles/getNewsBySlug/${encodeURIComponent(slug)}`;
};

const extractImageFromContent = (htmlContent) => {
  if (!htmlContent) return '/DefaultImages/news_default_img.jpeg';

  let imageUrl = '/DefaultImages/news_default_img.jpeg';
  parse(htmlContent, {
    replace: (domNode) => {
      if (domNode.name === 'img' && domNode.attribs?.src) {
        imageUrl = domNode.attribs.src;
      }
      return null;
    },
  });

  const serverBaseURL = 'http://localhost:5000';
  if (!imageUrl.startsWith('http')) {
    imageUrl = `${serverBaseURL}${imageUrl}`;
  }

  return imageUrl;
};

// Styled Components
const CustomSelect = styled(Select)(() => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#AD62AA',
    transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#C37028',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#C37028',
    boxShadow: '0 0 5px rgba(195, 112, 40, 0.5)',
  },
  '& .MuiSelect-select': {
    color: '#333',
  },
}));

const CustomTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#AD62AA',
      transition: 'border-color 0.3s ease',
    },
    '&:hover': {
      transform: 'translateY(-2px)',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#C37028',
      },
    },
    '&.Mui-focused': {
      transform: 'translateY(-2px)',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#C37028',
      },
      boxShadow: '0 4px 8px rgba(195, 112, 40, 0.2)',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#AD62AA',
    transition: 'color 0.3s ease, transform 0.3s ease',
    '&.Mui-focused': {
      color: '#C37028',
    },
  },
  '& .MuiInputBase-input': {
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(173, 98, 170, 0.04)',
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiInputBase-input': {
    backgroundColor: 'rgba(195, 112, 40, 0.04)',
  },
}));

// Custom Button
const CustomButton = styled(Button)(() => ({
  fontWeight: 'bold',
  backgroundColor: '#AD62AA',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: '#C37028',
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 8px rgba(195, 112, 40, 0.2)',
  },
}));

function Articles() {
  const [articlesData, setArticlesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [authorDetails, setAuthorDetails] = useState({});
  const [category, setCategory] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setLoading(true);
        const data = {
          categoryName: category === 'all' ? '' : category,
          type: 'articles',
          count: 100,
        };
        const response = await getFilteredNewsData(data);

        if (response?.data?.success && Array.isArray(response.data.latestNews)) {
          setArticlesData(response.data.latestNews);

          const authorIds = response.data.latestNews
            .map((article) => article.author)
            .filter((authorId) => authorId);

          const uniqueAuthorIds = [...new Set(authorIds)];

          const authorsData = {};
          await Promise.all(
            uniqueAuthorIds.map(async (authorId) => {
              try {
                const authorResponse = await getAuthorsById(authorId);
                if (authorResponse?.data?.success) {
                  authorsData[authorId] = authorResponse.data.author;
                }
              } catch (error) {
                console.error(`Error fetching author ${authorId}:`, error);
              }
            })
          );

          setAuthorDetails(authorsData);
        } else {
          setError('No articles found.');
        }
      } catch (err) {
        setError('Failed to fetch articles: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [category]);

  const getAuthorDisplay = (authorId) => {
    if (!authorId) return 'Unknown Author';
    const authorData = authorDetails[authorId];
    return authorData?.name || 'Unknown Author';
  };

  const filteredArticles = articlesData.filter(
    (article) =>
      article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      getAuthorDisplay(article.author).toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress sx={{ color: '#AD62AA' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" sx={{ textAlign: 'center', mt: 2 }}>
        {error}
      </Typography>
    );
  }

  return (
    <div>
    <Box sx={{ mt: { xs: 0, lg: 3 }, padding: 2 }}>
      <Box sx={{ mb: 2, ml: { xs: 0, lg: 2 }, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <FormControl fullWidth sx={{ maxWidth: { xs: '100%', sm: '200px' } }}>
          <InputLabel sx={{ color: '#AD62AA' }}>Category</InputLabel>
          <CustomSelect
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            label="Category"
            fullWidth
          >
            <MenuItem value="all">All Articles</MenuItem>
            <MenuItem value="general">General Articles</MenuItem>
            <MenuItem value="editorial">Editorial</MenuItem>
            <MenuItem value="doctoral">Doctoral Writings</MenuItem>
            <MenuItem value="features">Features</MenuItem>
            <MenuItem value="biographies">Biographies</MenuItem>
            <MenuItem value="english">English Articles</MenuItem>
            <MenuItem value="interviews">Interviews</MenuItem>
          </CustomSelect>
        </FormControl>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomButton variant="contained" sx={{ ml: { xs: 2 } }}>
            {category.charAt(0).toUpperCase() + category.slice(1)} Articles
          </CustomButton>
        </Grid>

        {filteredArticles.map((article, index) => {
          const imageUrl = extractImageFromContent(article.content);
          const articleDetailUrl = formatNewsDetailUrl(article.slug);
          const authorDisplay = getAuthorDisplay(article.author);

          return (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box
                className="article-item"
                sx={{
                  borderRadius: 2,
                  ml: { xs: 2 },
                  overflow: 'hidden',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  },
                }}
              >
                <Box
                  component="img"
                  src={imageUrl}
                  alt={article.title}
                  sx={{
                    width: '100%',
                    height: 200,
                    objectFit: 'cover',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                />
                <Box sx={{ p: 1 }}>
                  <Link to={articleDetailUrl} style={{ textDecoration: 'none' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 'bold',
                        color: '#C37028',
                        transition: 'color 0.3s ease',
                        '&:hover': {
                          color: '#AD62AA',
                        },
                      }}
                    >
                      {article.title}
                    </Typography>
                  </Link>
                  <Typography
                    variant="body2"
                    sx={{
                      color: 'text.secondary',
                      mt: 1,
                      transition: 'color 0.3s ease',
                    }}
                  >
                    <strong>By:</strong> {authorDisplay}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>

      {/* No padding for the Box containing the image */}
     
    </Box>
 <Box sx={{ mb: 0, ml: 0, mt: 2 }}>

                <Magazine />
           
</Box>
</div>
  );
}

export default Articles;
