import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Box, Button, TextField, Typography, Container, Grid, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllCategories, postNewsarticles, getAuthors, uploadPhoto, getNewsarticlesById, updateNewsarticles } from '../../Services/AdminServices';
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer';
import toast, { Toaster } from 'react-hot-toast';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateNews = ({ ...props }) => {
  const navigate = useNavigate();
  const reactQuillRef = useRef(null);
  const { slug } = useParams();  // Get slug from URL
  const [date, setDate] = useState('');
  const [views, setViews] = useState(0);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categoryType, setCategoryType] = useState('news');
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [tags, setTags] = useState('');
  const [authors, setAuthors] = useState([]);
  const [selectedAuthor, setSelectedAuthor] = useState('');
  const [authorLoading, setAuthorLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const response = await getAllCategories();
        const filteredCategories = response?.data?.categories.filter(
          (category) => category.type === categoryType
        );
        setCategories(filteredCategories || []);
      } catch (error) {
        toast.error('Error fetching categories');
        setCategories([]);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [categoryType]);

  // Fetch authors
  useEffect(() => {
    const fetchAuthors = async () => {
      try {
        setAuthorLoading(true);
        const response = await getAuthors();
        const authorsList = response?.data?.authors || [];
        setAuthors(authorsList);
      } catch (error) {
        console.error('Error fetching authors:', error);
        toast.error('Error fetching authors');
        setAuthors([]);
      } finally {
        setAuthorLoading(false);
      }
    };

    fetchAuthors();
  }, []);

  // Fetch news article for editing
  useEffect(() => {
    if (slug) {
      setIsEditMode(true);
      const fetchArticle = async () => {
        try {
          const response = await getNewsarticlesById(slug);
          if (response?.data?.success) {
            const article = response.data.newsArticle;
            // Populate form fields with article data
            setTitle(article.title);
            setSubtitle(article.subtitle);
            setContent(article.content);
            setTags(article.tags.join(', '));
            setSelectedCategory(article.category);
            setSelectedAuthor(article.author._id);
          }
        } catch (error) {
          toast.error('Error fetching article for editing');
        }
      };

      fetchArticle();
    }
  }, [slug]);

  // Image handler for ReactQuill
  const imageHandler = useCallback(async () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      if (input.files && input.files[0]) {
        const file = input.files[0];
        
        if (file.size > 5 * 1024 * 1024) {
          toast.error("File size should be less than 5MB");
          return;
        }

        if (!file.type.startsWith('image/')) {
          toast.error("Please upload only image files");
          return;
        }

        const formData = new FormData();
        formData.append('image', file);

        try {
          setSpinner(true);
          toast.loading("Uploading image...");
          const response = await uploadPhoto(formData);
          if (!response?.data?.file?.url) {
            throw new Error('Invalid response from server');
          }

          const url = response.data.file.url;
          const quill = reactQuillRef.current.getEditor();
          if (quill) {
            const range = quill.getSelection();
            if (range) {
              quill.insertEmbed(range.index, "image", url);
              quill.setSelection(range.index + 1);
              toast.success("Image uploaded successfully");
            }
          }
        } catch (error) {
          console.error("Upload error:", error);
          toast.error(error.response?.data?.message || "Failed to upload image");
        } finally {
          setSpinner(false);
          toast.dismiss();
        }
      }
    };
  }, []);

  const modules = {
    toolbar: {
      container: [
        [{ 'size': ['8px', '10px', '12px', '14px', '18px', '24px', '36px'] }],
        ['bold', 'italic'],
        [{ 'align': [] }],
        ['image'],
        [{ 'color': [] }, { 'background': [] }],
        ["undo", "redo"]
      ],
      handlers: {
        image: imageHandler,
      },
    },
  };

  // Handle form submission (create or update)
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const articleData = {
      type: categoryType,
      category: selectedCategory,
      title,
      subtitle,
      content,
      tags: tags.split(',').map(tag => tag.trim()),
      author: selectedAuthor,
    };

    try {
      if (isEditMode) {
        // Update existing article
        const response = await updateNewsarticles(slug, articleData);
        if (response?.data?.success) {
          toast.success('News article updated successfully');
          navigate('/admin/publish');
        }
      } else {
        // Create new article
        const response = await postNewsarticles(articleData);
        if (response?.data?.success) {
          toast.success('News article created successfully');
          navigate('/admin/publish');
        }
      }
    } catch (error) {
      toast.error('Error submitting the article');
    }
  };

  return (
    <Container maxWidth="lg" sx={{ padding: { xs: 4, sm: 4 } }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <ResponsiveDrawer {...props} />
        </Grid>

        <Grid item xs={12} lg={10} sx={{ mt: 12 }}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
              {isEditMode ? 'Edit News' : 'Create News'}
            </Typography>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Category Type</InputLabel>
              <Select
                value={categoryType}
                onChange={(e) => setCategoryType(e.target.value)}
                label="Category Type"
              >
                <MenuItem value="news">News</MenuItem>
                <MenuItem value="articles">Articles</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Category</InputLabel>
              <Select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                label="Category"
                disabled={loading || categories.length === 0}
              >
                {loading ? (
                  <MenuItem disabled>Loading categories...</MenuItem>
                ) : categories.length === 0 ? (
                  <MenuItem disabled>No categories available</MenuItem>
                ) : (
                  categories.map((category) => (
                    <MenuItem key={category._id} value={category._id}>
                      {category.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Author</InputLabel>
              <Select
                value={selectedAuthor}
                onChange={(e) => setSelectedAuthor(e.target.value)}
                label="Author"
                disabled={authorLoading}
                renderValue={(selected) => {
                  const author = authors.find(a => a._id === selected);
                  return author ? author.name : '';
                }}
              >
                {authorLoading ? (
                  <MenuItem disabled>Loading authors...</MenuItem>
                ) : authors.length === 0 ? (
                  <MenuItem disabled>No authors available</MenuItem>
                ) : (
                  authors.map((author) => (
                    <MenuItem key={author._id} value={author._id}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        {author.photo && (
                          <img
                            src={author.photo}
                            alt={author.name}
                            style={{ width: 30, height: 30, borderRadius: '50%', objectFit: 'cover' }}
                          />
                        )}
                        <Box>
                          <Typography>{author.name}</Typography>
                          <Typography variant="caption" color="textSecondary">
                            {author.bio?.substring(0, 50)}...
                          </Typography>
                        </Box>
                      </Box>
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>

            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              sx={{ mb: 2 }}
            />

            <TextField
              label="Subtitle"
              variant="outlined"
              fullWidth
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
              required
              sx={{ mb: 2 }}
            />

            <TextField
              label="Tags (comma separated)"
              variant="outlined"
              fullWidth
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              sx={{ mb: 2 }}
            />

            <ReactQuill
              value={content}
              onChange={setContent}
              theme="snow"
              placeholder="Write your article content here..."
              modules={modules}
              style={{ minHeight: '300px', marginBottom: '20px' }}
              ref={reactQuillRef}
            />

            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end" mt={2} gap={4}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ backgroundColor: '#d18898', color: 'white', mr: 4 }}
                  onClick={handleSubmit}
                >
                  {isEditMode ? 'Update News' : 'Publish News'}
                </Button>

                <Button
                  variant="contained"
                  sx={{ backgroundColor: '#A56266', color: 'white' }}
                >
                  Save to Draft
                </Button>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
};

export default CreateNews;
