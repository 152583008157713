import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, IconButton, Container, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer'; 
import { useNavigate } from 'react-router-dom';
import { getAllCategories, deleteCategoryById } from '../../Services/AdminServices'; 
import toast, { Toaster } from 'react-hot-toast';

const Item = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  padding: 20,
}));

const AdminCategories = ({ ...props }) => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await getAllCategories();
      setCategories(response?.data?.categories || []);
    } catch (error) {
      toast.error('Error fetching categories');
    }
  };

  const handleClick = () => {
    navigate('/admin/newcategories');
  };

  const handleDelete = async (categoryId) => {
    try {
      const response = await deleteCategoryById(categoryId);
      toast.success(response?.data?.message);
      fetchCategories();
    } catch (error) {
      toast.error('Error deleting category');
    }
  };

  const handleEdit = (categoryId) => {
    navigate(`/admin/newcategories/${categoryId}`);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Updated filtering to use 'name' instead of 'categoryName'
  const filteredCategories = categories.filter(
    (cat) => cat.name && cat.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container maxWidth="lg" sx={{ padding: 0 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <ResponsiveDrawer {...props} />
        </Grid>

        <Grid item xs={12} lg={10} sx={{ mt: 12 }}>
          <Box sx={{ mb: 2 }}>
            <Grid container alignItems="center">
              <Grid item xs sx={{ pr: 50 }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginLeft: 3 }}>
                  Categories
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  placeholder="Search..."
                  size="small"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  sx={{ backgroundColor: '#F4F4F4', marginRight: 1, marginLeft: 3 }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#A56266", color: "white" }}
                  onClick={handleClick}
                >
                  <AddIcon /> Create
                </Button>
              </Grid>
            </Grid>
          </Box>

          <TableContainer component={Item}>
            <Table sx={{ minWidth: 320 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundColor: "#caada7", color: "white", fontWeight: "bold" }}>Action</TableCell>
                  <TableCell sx={{ backgroundColor: "#d18898", color: "white", fontWeight: "bold" }}>Category</TableCell>
                  <TableCell sx={{ backgroundColor: "#A56266", color: "white", fontWeight: "bold" }}>Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCategories.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={3} align="center">No categories found</TableCell>
                  </TableRow>
                ) : (
                  filteredCategories.map((cat, index) => (
                    <TableRow key={cat._id}>
                      <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                        <IconButton title="Edit Category" onClick={() => handleEdit(cat._id)}>
                          <EditIcon style={{ fontSize: '16px' }} />
                        </IconButton>
                        <IconButton title="Delete Category" onClick={() => handleDelete(cat._id)}>
                          <DeleteIcon style={{ fontSize: '16px' }} />
                        </IconButton>
                      </TableCell>
                      <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                        <span style={{ fontSize: 17, fontWeight: 'bold' }}>{cat.name}</span>
                      </TableCell>
                      <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                        <span style={{ fontWeight: 600 }}>{cat.type}</span>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
};

export default AdminCategories;