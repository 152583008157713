import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, IconButton, Container, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { getAllMagazines, deleteMagazineById } from '../../Services/AdminServices'; 
import toast, { Toaster } from 'react-hot-toast';
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer'; 

const Item = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  padding: 20,
}));

const AdminMagazine = ({ ...props }) => {
  const navigate = useNavigate();
  const [magazines, setMagazines] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchMagazines();
  }, []);

  const fetchMagazines = async () => {
    try {
      const response = await getAllMagazines();
      setMagazines(response?.data?.magazines || []);
    } catch (error) {
      toast.error('Error fetching magazines');
    }
  };

  const handleClick = () => {
    navigate('/admin/newmagazine'); // Navigate to create new magazine page
  };

  const handleDelete = async (magazineId) => {
    try {
      const response = await deleteMagazineById(magazineId);
      toast.success(response?.data?.message);
      fetchMagazines();
    } catch (error) {
      toast.error('Error deleting magazine');
    }
  };

  const handleEdit = (magazineId) => {
    navigate(`/admin/newmagazine/${magazineId}`); // Navigate to edit magazine page
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filtering magazines by name
  const filteredMagazines = magazines.filter(
    (mag) => mag.name && mag.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container maxWidth="lg" sx={{ padding: 0 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <ResponsiveDrawer {...props} />
        </Grid>

        <Grid item xs={12} lg={10} sx={{ mt: 12 }}>
          <Box sx={{ mb: 2 }}>
            <Grid container alignItems="center">
              <Grid item xs sx={{ pr: 50 }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginLeft: 3 }}>
                  Magazines
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  placeholder="Search..."
                  size="small"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  sx={{ backgroundColor: '#F4F4F4', marginRight: 1, marginLeft: 3 }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#A56266", color: "white" }}
                  onClick={handleClick}
                >
                  <AddIcon /> Create
                </Button>
              </Grid>
            </Grid>
          </Box>

          <TableContainer component={Item}>
            <Table sx={{ minWidth: 320 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundColor: "#caada7", color: "white", fontWeight: "bold" }}>Action</TableCell>
                  <TableCell sx={{ backgroundColor: "#d18898", color: "white", fontWeight: "bold" }}>Magazine</TableCell>
                  <TableCell sx={{ backgroundColor: "#A56266", color: "white", fontWeight: "bold" }}>Edition</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredMagazines.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={3} align="center">No magazines found</TableCell>
                  </TableRow>
                ) : (
                  filteredMagazines.map((mag, index) => (
                    <TableRow key={mag._id}>
                      <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                        <IconButton title="Edit Magazine" onClick={() => handleEdit(mag._id)}>
                          <EditIcon style={{ fontSize: '16px' }} />
                        </IconButton>
                        <IconButton title="Delete Magazine" onClick={() => handleDelete(mag._id)}>
                          <DeleteIcon style={{ fontSize: '16px' }} />
                        </IconButton>
                      </TableCell>
                      <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                        <span style={{ fontSize: 17, fontWeight: 'bold' }}>{mag.name}</span>
                      </TableCell>
                      <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                        <span style={{ fontWeight: 600 }}>{mag.edition}</span>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
};

export default AdminMagazine;
