import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, useTheme, useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';
import { getNewsarticlesById } from '../../../Services/AdminServices';

const Newsdetail = () => {
  const { slug } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchArticle = async () => {
      if (!slug) {
        setError('No article ID provided.');
        setLoading(false);
        return;
      }

      try {
        const decodedSlug = decodeURIComponent(slug);
        const response = await getNewsarticlesById(decodedSlug);

        if (response?.data?.success) {
          setBlog(response.data.article);
        } else {
          setError('Article not found.');
        }
      } catch (err) {
        console.error('Error fetching article:', err);
        setError('Failed to fetch article.');
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [slug]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!blog) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography>No article found.</Typography>
      </Box>
    );
  }

  const authorDisplay =
    blog.author && typeof blog.author === 'object' ? blog.author.name : blog.author || 'Unknown Author';

  return (
    <Box sx={{ backgroundColor: '#f8f8f8', overflowX: 'hidden' }}>
      <Container sx={{ mt: { xs: 1, sm: 6 }, ml: { xs: 1, sm: 10 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                p: 3,
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  mb: 1,
                  textAlign: 'left',
                  fontWeight: 500,
                  fontSize: { xs: '1.3rem', md: '2rem' },
                }}
              >
                {blog.title}
              </Typography>
              <Typography variant="body2" sx={{ color: '#555' }}>
                by {authorDisplay}
              </Typography>

              <Box
                className="blog-content"
                lang="ml" // Ensure proper language for Malayalam
                sx={{
                  '& img': {
                    maxWidth: '100%',
                    height: 'auto',
                    borderRadius: 1,
                    my: 2,
                  },
                  '& p': {
                    lineHeight: 1.6,
                    fontSize: '1.1rem',
                    marginBottom: '1rem',
                    wordSpacing: '0.05em',
                    hyphens: 'auto', 
                    textAlign: isMobile ? 'left' : 'justify',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                  },
                  fontFamily: "'Noto Sans Malayalam', sans-serif", 
                  overflowX: 'hidden',
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: blog.content }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Newsdetail;
