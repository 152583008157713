import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, IconButton, Container, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer'; 
import { useNavigate } from 'react-router-dom';
import { getAuthors, deleteAuthorById } from '../../Services/AdminServices';  // Assuming you have these services
import toast, { Toaster } from 'react-hot-toast';

const Item = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  padding: 20,
}));

const AdminAuthors = ({ ...props }) => {
  const navigate = useNavigate();
  const [authors, setAuthors] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchAuthors();
  }, []);

  const fetchAuthors = async () => {
    try {
      const response = await getAuthors();  // Fetch authors from the API
      setAuthors(response?.data?.authors || []);
    } catch (error) {
      toast.error('Error fetching authors');
    }
  };

  const handleClick = () => {
    navigate('/admin/newauthor');  // Navigate to the page for adding new authors
  };

  const handleDelete = async (authorId) => {
    try {
      const response = await deleteAuthorById(authorId);  // Delete the author by ID
      toast.success(response?.data?.message);
      fetchAuthors();  // Refresh the author list
    } catch (error) {
      toast.error('Error deleting author');
    }
  };

  const handleEdit = (authorId) => {
    navigate(`/admin/newauthors/${authorId}`);  // Navigate to the edit page for the author
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter authors by name
  const filteredAuthors = authors.filter(
    (author) => author.name && author.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container maxWidth="lg" sx={{ padding: 0 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <ResponsiveDrawer {...props} />
        </Grid>

        <Grid item xs={12} lg={10} sx={{ mt: 12 }}>
          <Box sx={{ mb: 2 }}>
            <Grid container alignItems="center">
              <Grid item xs sx={{ pr: 50 }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginLeft: 3 }}>
                  Authors
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  placeholder="Search..."
                  size="small"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  sx={{ backgroundColor: '#F4F4F4', marginRight: 1, marginLeft: 3 }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#A56266", color: "white" }}
                  onClick={handleClick}
                >
                  <AddIcon /> Create
                </Button>
              </Grid>
            </Grid>
          </Box>

          <TableContainer component={Item}>
            <Table sx={{ minWidth: 320 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundColor: "#caada7", color: "white", fontWeight: "bold" }}>Action</TableCell>
                  <TableCell sx={{ backgroundColor: "#d18898", color: "white", fontWeight: "bold" }}>Author</TableCell>
                  <TableCell sx={{ backgroundColor: "#A56266", color: "white", fontWeight: "bold" }}>Bio</TableCell>
                  <TableCell sx={{ backgroundColor: "#d18898", color: "white", fontWeight: "bold" }}>Photo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAuthors.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">No authors found</TableCell>
                  </TableRow>
                ) : (
                  filteredAuthors.map((author, index) => (
                    <TableRow key={author._id}>
                      <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                        <IconButton title="Edit Author" onClick={() => handleEdit(author._id)}>
                          <EditIcon style={{ fontSize: '16px' }} />
                        </IconButton>
                        <IconButton title="Delete Author" onClick={() => handleDelete(author._id)}>
                          <DeleteIcon style={{ fontSize: '16px' }} />
                        </IconButton>
                      </TableCell>
                      <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                        <span style={{ fontSize: 17, fontWeight: 'bold' }}>{author.name}</span>
                      </TableCell>
                      <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                        <span>{author.bio}</span>
                      </TableCell>
                      <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                        <img src={author.photo} alt={author.name} style={{ width: 50, height: 50, borderRadius: '50%' }} />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
};

export default AdminAuthors;
