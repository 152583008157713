import React from 'react';
import slide from '../../../Assets/slide1.jpg';

function Magazine() {
  return (
    <div style={{ marginBottom: '-8px' }}>
      <img 
        src={slide} 
        alt="Magazine Slide" 
        style={{ width: '100%', height: '100%',  }} // Optional styles
      />
    </div>
  );
}

export default Magazine;
