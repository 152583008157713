import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Button, Divider, Hidden } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { getFilteredNewsData } from '../../../Services/AdminServices';
import parse from 'html-react-parser';
import { saveToLocalStorage } from '../../../Helper/helper';
import './SecularNews.css';
import ad2 from '../../../Assets/WhatsApp Image 2024-11-23 at 14.04.32.jpeg';
import test from '../../../Assets/test.jpg';

// Keep existing utility functions
const extractImageFromContent = (htmlContent) => {
  if (!htmlContent) return '/DefaultImages/news_default_img.jpeg';

  let imageUrl = '/DefaultImages/news_default_img.jpeg';
  parse(htmlContent, {
    replace: (domNode) => {
      if (domNode.name === 'img' && domNode.attribs?.src) {
        imageUrl = domNode.attribs.src;
      }
      return null;
    },
  });

  return imageUrl;
};

const getFilteredContent = (htmlContent) => {
  if (!htmlContent) return '';

  let textContent = '';
  const parsed = parse(htmlContent, {
    replace: (domNode) => {
      if (domNode.type === 'text') {
        textContent += domNode.data + ' ';
      }
      if (domNode.name === 'img') {
        return null;
      }
      return domNode;
    },
  });

  return textContent
    .trim()
    .replace(/\s+/g, ' ')
    .slice(0, 400) + '...';
};

function SecularNews() {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        setLoading(true);
        const data = {
          categoryName: 'secular',
          type: 'news',
          count: 3,
        };
        const response = await getFilteredNewsData(data);

        if (response?.data?.success && Array.isArray(response.data.latestNews)) {
          setNewsData(response.data.latestNews);
        } else {
          setError('No secular news found.');
        }
      } catch (err) {
        setError('Failed to fetch secular news: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  const AdSection = () => (
    <Box sx={{ width: '100%' }}>
      <Box 
        component="img" 
        src={test} 
        alt="Advertisement" 
        className="adImage" 
        sx={{ 
          width: '100%', 
          height: 'auto', 
          marginBottom: '2px',
          display: 'block'
        }} 
      />
      <Box 
        component="img" 
        src={ad2} 
        alt="Advertisement" 
        className="adImage" 
        sx={{ 
          width: '100%', 
          height: 'auto',
          display: 'block'
        }} 
      />
    </Box>
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography>Loading secular news...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <div>
      <Grid container spacing={2}>
        {/* Left Side Grid - News Content */}
        <Grid item xs={12} sm={8} md={8}>
          <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 1, }}>
            <Button
              variant="contained"
              sx={{
                fontWeight: 'bold',
                backgroundColor: '#AD62AA',
                mt:1, mb: 2, ml: 3,
                '&:hover': { backgroundColor: '#04049a' },
              }}
            >
              Secular News
            </Button>
            {/* <Button
              variant="text"
              sx={{ color: '#C37028', fontWeight: 'bold' }}
              onClick={() => navigate('/news/secular')}
            >
              See All
            </Button> */}
          </Grid>

          {/* Show ads here on xs screens */}
          

          {newsData.map((news) => {
            const imageUrl = extractImageFromContent(news.content);
            const filteredContent = getFilteredContent(news.content);
            const newsDetailUrl = `/news-articles/getNewsBySlug/${encodeURIComponent(news.slug)}`;

            return (
              <Grid item xs={12} key={news.slug}>
                <Grid container spacing={2} sx={{ padding: { xs: 1, sm: 2 } }}>
                  <Grid item xs={4}>
                    <Box
                      component="img"
                      src={imageUrl}
                      alt={`News ${news.slug}`}
                      sx={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        mt:{xs:-2,sm:0},
                        ml:{xs:5,sm:1},
                       
                      }}
                      onClick={() => {
                        saveToLocalStorage(window.location.pathname, window.scrollY);
                        navigate(newsDetailUrl);
                      }}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Link 
                      to={newsDetailUrl} 
                      style={{ textDecoration: 'none' }} 
                      onClick={() => saveToLocalStorage(window.location.pathname, window.scrollY)}
                    >
                      <Typography
                        variant="h7"
                        sx={{
                          textAlign: 'left',
                          fontWeight: 'bold',
                          color: 'black',
                          mt: { xs: -1, sm: 1 },
                          fontSize: {xs:'0.9rem',lg:'1rem'},
                          display: 'block',
                          ml: { xs: 6, sm: 2 },
                          mr:{xs:1}
                         
                        }}
                      >
                        {news.title}
                      </Typography>
                    </Link>
                    <Hidden smDown>
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'text.secondary',
                          mt: 1,
                          fontSize: {xs:'0.9rem',lg:'0.9rem'},
                          lineHeight: 1.5,
                          ml: { xs: 2, sm: 2 },
                         
                        }}
                      >
                        {filteredContent}
                      </Typography>
                    </Hidden>
                  </Grid>
                </Grid>
                <Divider 
    className="divider" 
    sx={{ mt: { xs: -1, sm: 0 }, my: 2 }} 
  />

              </Grid>
            );
          })}
        </Grid>

        {/* Right Side Grid - Ad Section (hidden on xs screens) */}
        <Hidden xsDown>
          <Grid item sm={4} md={4} >
            <AdSection />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
}

export default SecularNews;