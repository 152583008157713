import React ,{useEffect} from 'react';
import { Grid, Typography, Box, Button, Container, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import aboutdetail from '../../../Assets/wallpaper.jpg';
import img1 from '../../../Assets/publications/book26.jpg';
import img2 from '../../../Assets/publications/book5.jpg';
import img3 from '../../../Assets/publications/book4.jpg';
import img4 from '../../../Assets/publications/book2.jpg';
import img5 from '../../../Assets/publications/book3.jpg';
import img6 from '../../../Assets/publications/book1.jpg';
import Magazine from '../HomePage/Magazine';
const bookData = [
  {
    title: 'നാഥൻ നടത്തിയ വഴികൾ',
    author: 'Joy Nedukunnam',
    description:'A gripping tale of adventure and survival that explores the depths of the human spirit.',
    price: '₹500',
    image: img6,
  },
  {
    title: 'ക്രിസ്തീയ ആശയവിനിമയം',
    author: 'Rev.K.V Baby',
    description: 'A gripping tale of adventure and survival that explores the depths of the human spirit.',
    price:'₹500',
    image: img1,
  },
  {
    title: 'കനൽ വഴികളിലെ സംഗീതം',
    author: 'Joy Nedukunnam',
    description: 'A gripping tale of adventure and survival that explores the depths of the human spirit.',
    price:'₹500',
    image: img2,
  },
  {
    title: 'മരണാനന്തരം',
    author: 'Alex M Kuruvila',
    description: 'A gripping tale of adventure and survival that explores the depths of the human spirit.',
    price: '₹500',
    image: img3,
  },
  {
    title: 'യേശുവിന്റെ കൂടെ',
    author: 'Sharon Writers Forum',
    description: 'A gripping tale of adventure and survival that explores the depths of the human spirit.',
    price: '₹500',
    image: img4,
  },
  {
    title: 'വഴികാട്ടി',
    author: 'Sajo Thonnikuzhiyil',
    description: 'A gripping tale of adventure and survival that explores the depths of the human spirit.',
    price:'₹500',
    image: img5,
  },
];

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${aboutdetail})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  padding: theme.spacing(8, 0),
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const HeroContent = styled(Box)(({ theme }) => ({
  zIndex: 1,
  maxWidth: '800px',
  padding: theme.spacing(4),
}));

function Publications() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);
  const handleViewDetails = () => {
    navigate('/');
  };

  // BookCard Component
  const BookCard = ({ book }) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2,
        backgroundColor: '#eceff1',
        padding: theme => theme.spacing(4), // Reduced padding inside the card
        maxWidth: 600, // Limit card width
        width: '100%',
        boxShadow: 1, // Added shadow for better visibility
        height: 'auto', // Make sure card height adapts to content
        ml:{xs:2},
      mt:{xs:-2}
      }}
    >
      <Grid container spacing={2}>
        {/* Image Section */}
        <Grid item xs={12}>
          <img
            src={book.image}
            alt={`Book cover of ${book.title}`}
            style={{
              width: '100%',
              height: '200px', // Set a fixed height for image, you can adjust this
              objectFit: 'contain', // Ensures the full image is visible without cropping
              borderRadius: 4,
            }}
          />
        </Grid>

        {/* Content Section */}
        <Grid item xs={12}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', color: '#333', fontSize: '1rem' }}>
            {book.title}
          </Typography>
          <Typography variant="body2" sx={{ color: '#37474f', fontSize: '0.75rem', marginBottom: 1,mt:1 }}>
            By <strong>{book.author}</strong>
          </Typography>
          <Typography variant="body2" sx={{ color: '#424242', fontSize: '0.8rem' }}>
            {book.description}
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 'bold',color: '#37474f', marginTop: 1, fontSize: '0.875rem' }}>
            {book.price}
          </Typography>

          <Button
            variant="contained"
            sx={{
              mt: 2,
              backgroundColor: '#AD62AA',
              color: 'white',
              fontSize: '0.75rem',
              padding: '6px 16px',
              '&:hover': { backgroundColor: '#F78C6B' },
            }}
            onClick={handleViewDetails}
          >
            View Details
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2 }} /> {/* Divider between each card */}
    </Box>
  );

  return (
    <div>
    <Box sx={{ mt: { xs: 0, lg: 3 } }}>
      {/* Hero Section */}
      <HeroSection>
        <HeroContent>
          <Typography variant="h4" sx={{ fontWeight: 600, color: '#AD62AA', fontSize: { xs: '1.5rem', md: '2.5rem' }, mt: 2 }}>
            Publications
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem', color: '#AD62AA' }}>
            Explore a wide range of books for your knowledge and spiritual growth.
          </Typography>
        </HeroContent>
      </HeroSection>

      {/* Book Cards */}
      <Container maxWidth="lg" sx={{ mt: 0, padding: { xs: 4 } }}>
        <Grid container spacing={4}>
          {bookData.map((book, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <BookCard book={book} />
            </Grid>
          ))}
        </Grid>
      </Container>
      
    </Box>


<Box sx={{ mb: 0, ml: 0, mt: 2 }}>

<Magazine />

</Box>
</div>


  );
}

export default Publications;
