import React ,{useEffect} from 'react';
import { Container, Grid, Typography, Box, Button } from '@mui/material';
import bookImage from '../../../Assets/img1.jpg'; // Replace with the path to your book image
import { useNavigate } from 'react-router-dom';

function Download() {


  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  const navigate = useNavigate();

  const handlePaymentClick = () => {
    navigate('/payment');
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: '20px' }}>
      <Box
  sx={{
    borderRadius: '12px',
    padding: '15px', // Reduced padding
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f9f9f9', // Light background color
  }}
>
        <Grid container spacing={2}> {/* Reduced spacing */}
          {/* Book Image */}
          <Grid item xs={12} md={5} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              src={bookImage}
              alt="Book cover"
              style={{ width: '100%', maxWidth: '250px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
            />
          </Grid>

          {/* Book Details */}
          <Grid item xs={12} md={7}>
            <Typography variant="h5" component="h1" style={{ marginBottom: '5px', textAlign: 'left', color: '#AD62AA' ,fontWeight:'bold'}}>
             THE PURPOSE DRIVEN LIFE
            </Typography>

            <Typography variant="h6" component="h2" style={{ marginBottom: '5px', color: 'gray', textAlign: 'left' }}>
              By <span style={{ color: 'black' }}>James Allen</span>
            </Typography>

            <Typography variant="subtitle1" component="h2" style={{ marginBottom: '5px', textAlign: 'left' }}>
              Price: <span style={{ color: '#AD62AA',fontSize:'1.8rem' }}>₹ 260</span>
            </Typography>

            <Box mb={1}>
              <Typography variant="body2" style={{ textAlign: 'left' }}>
                 THE PURPOSE DRIVEN LIFE PDF edition and other Jack London books available for free download from our library.
              </Typography>
              
            </Box>

            <Box mt={2}>
              <Typography variant="h6" component="h2" style={{ marginBottom: '5px', textAlign: 'left', fontWeight: 'bold', color: '#D91656' }}>
                Synopsis
              </Typography>
              <Typography variant="body2" paragraph style={{ textAlign: 'left', color: '#424242' }}>
              <span style={{ fontWeight: 'bold' }}>THE PURPOSE DRIVEN LIFE</span> is a novel by Jack London based on Darwin’s theory; Survival of the fittest. A dog which is nurtured as a pet unearths the wolf inside it when exposed to brutal and cruel treatments under harsh circumstances. The story is set in the frozen Canadian Klondike gold rush during the 1890s. The central character of this story revolves around Buck, a domesticated dog.
                <br /><br />
                Stolen to compensate the gambling debts of the gardener, Buck is sold to the gambling club. The club sells him to Canadian dispatchers and ultimately moves him to a Klondike region with extreme climatic conditions where he learns to survive the cold winter. In a rivalry with Spitz, Buck wins and emerges as the leader of the team.
              </Typography>
            </Box>

            <Box mt={2} mb={4} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#AD62AA',
                  color: 'white',
                  '&:hover': { backgroundColor: '#AD62AA' },
                }}
                onClick={handlePaymentClick}
              >
                Go to Payment
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Download;
