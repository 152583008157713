import React from 'react';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  Paper,
  Container,
  Grid,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer'; 
const AdminAds = ({ ...props }) => {
  return (
    <Container maxWidth="lg" sx={{ padding: 0 }}>
      <Grid container spacing={2}>
        {/* Sidebar (Placeholder for actual component) */}
        <Grid item xs={12} lg={2}>
       
        <ResponsiveDrawer {...props} />
        </Grid>

        {/* Main Content */}
        <Grid item xs={12} lg={10} sx={{ mt: 12 }}>
          <Box sx={{ padding: 3 }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
              New Ad
            </Typography>
            <Box sx={{ mb: 2 }}>
              <FormControl fullWidth required>
                <InputLabel id="type-select-label">Type</InputLabel>
                <Select labelId="type-select-label" defaultValue="">
                  <MenuItem value="box">Box</MenuItem>
                  <MenuItem value="banner">Banner</MenuItem>
                  <MenuItem value="premium">Premium</MenuItem>
                </Select>
              </FormControl>
              <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
                Required image size: 300x250 pixels
              </Typography>
              <Box display="flex" alignItems="center" mb={2}>
                <Paper variant="outlined" sx={{ border: '1px solid grey', padding: 1 }}>
                  <img
                    src="/upload.png"
                    alt="upload placeholder"
                    style={{ height: "110px", width: "175px", objectFit: 'contain' }}
                  />
                </Paper>
              </Box>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                sx={{ mb: 2 }}
              >
                Upload file
                <input type="file" hidden accept="image/*" />
              </Button>
              <TextField
                disabled
                label="Size"
                variant="outlined"
                value="300x250"
                fullWidth
                sx={{ mb: 2 }}
              />
              <FormControl fullWidth required sx={{ mb: 2 }}>
                <InputLabel id="plan-select-label">Plan</InputLabel>
                <Select labelId="plan-select-label" defaultValue="">
                  <MenuItem value="3">3 months</MenuItem>
                  <MenuItem value="6">6 months</MenuItem>
                  <MenuItem value="12">12 months</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Customer Details"
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Link"
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
              />
            </Box>
            <Button
              variant='contained'
              sx={{ backgroundColor: "#A56266", color: "white", borderRadius: 10 }}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminAds;
