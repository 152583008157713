import { Routes, Route } from 'react-router-dom';
import HomePage from '../User/Pages/HomePage';
import Publications from '../User/Components/Publications/Publications';
import Courses from '../User/Components/Home/Courses';
import Download from '../User/Components/Publications/Download';
import Payment from '../User/Components/Publications/Payment';
import Contact from '../User/Components/Home/Contact';
import MonthlyMagazine from '../User/Components/Home/MonthlyMagazine';
import Newsdetail from '../User/Components/Home/NewsDetail';
import Articles from '../User/Components/Home/Articles';
import Aboutus from '../User/Components/Home/About';
import News from '../User/Components/Home/News';
const UserRoutes = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/publications" element={<Publications />} />
    <Route path="/courses" element={<Courses />} />
    <Route path="/download" element={<Download />} />
    <Route path="/payment" element={<Payment />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/magazine" element={<MonthlyMagazine />} />
    <Route path="/news-articles/getNewsBySlug/:slug" element={<Newsdetail />} />

    <Route path="/about" element={<Aboutus />} />
    <Route path="/articles" element={<Articles />} />
    <Route path="/newspage" element={<News />} />
  </Routes>
);

export default UserRoutes;