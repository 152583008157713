import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Grid, FormControl, InputLabel, Select, MenuItem, Paper } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ResponsiveDrawer from '../Components/Drawer/Drawer'; 
import { Toaster, toast } from 'react-hot-toast'; // Ensure you have this installed
import { postBooks } from '../../Services/AdminServices';  // Import the postBooks API call

function NewPublication(props) {
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [type, setType] = useState('');
  const [size, setSize] = useState('');
  const [plan, setPlan] = useState('');
  
  const [titleError, setTitleError] = useState(false);
  const [authorError, setAuthorError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    if (!title || !author || !price || !image) {
      if (!title) setTitleError(true);
      if (!author) setAuthorError(true);
      if (!price) setPriceError(true);
      if (!image) setImageError(true);
      return;
    }

    // Prepare the data to send to the backend
    const formData = new FormData();
    formData.append('title', title);
    formData.append('author', author);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('image', image);

    try {
      // API call to create a new publication
      const response = await postBooks(formData);
      if (response?.data?.success) {
        toast.success("Publication created successfully!");
        // Clear the form after successful submission
        setTitle('');
        setAuthor('');
        setDescription('');
        setPrice('');
        setImage(null);
        setImagePreview(null);
      }
    } catch (error) {
      toast.error("Failed to create publication.");
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));  // Preview the image
      setImageError(false);  // Reset error if image is selected
    }
  };

  return (
    <Container maxWidth="lg" sx={{ padding: 0 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <ResponsiveDrawer {...props} />
        </Grid>
        <Grid item xs={12} lg={10} sx={{ mt: 10 }}>
          <Box sx={{ padding: 4, height: '100%' }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
              Add New Publication
            </Typography>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              
              {/* Image Upload */}
              <Box display="flex" flexDirection="column" alignItems="flex-start" mt={2}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{ backgroundColor: '#A56266', color: 'white', mb: 2 }}
                >
                  Upload Image
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </Button>
                {imagePreview && (
                  <Box mt={2}>
                    <img 
                      src={imagePreview} 
                      alt="Uploaded Preview" 
                      style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '8px' }} 
                    />
                  </Box>
                )}
                {imageError && <Typography color="error">Please upload an image</Typography>}
              </Box>

              {/* Other Form Fields */}
              <TextField 
                label="Title" 
                variant="outlined" 
                fullWidth 
                value={title} 
                onChange={(e) => setTitle(e.target.value)} 
                sx={{ mb: 2, backgroundColor: '#f3f4d3' }} 
                error={titleError}
                helperText={titleError ? 'Title is required' : ''}
              />
              <TextField 
                label="Author" 
                variant="outlined" 
                fullWidth 
                value={author} 
                onChange={(e) => setAuthor(e.target.value)} 
                sx={{ mb: 2, backgroundColor: '#e0dab8' }} 
                error={authorError}
                helperText={authorError ? 'Author is required' : ''}
              />
              <TextField 
                label="Description" 
                variant="outlined" 
                fullWidth 
                multiline 
                rows={4} 
                value={description} 
                onChange={(e) => setDescription(e.target.value)} 
                sx={{ mb: 2 , backgroundColor: '#f3f4d3'}} 
              />
              <TextField 
                label="Price" 
                variant="outlined" 
                fullWidth 
                value={price} 
                onChange={(e) => setPrice(e.target.value)} 
                sx={{ mb: 2 , backgroundColor: '#e0dab8'}} 
                error={priceError}
                helperText={priceError ? 'Price is required' : ''}
              />

              {/* Submit Button */}
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button 
                  variant="contained" 
                  type="submit" 
                  sx={{ backgroundColor: "#A56266", color: "white", fontSize: 15 }}
                >
                  Save
                </Button>
              </Box>
            </form>

            <Toaster position="top-center" reverseOrder={false} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NewPublication;
