import React, { useEffect, useState } from 'react';
import {
  Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography,
  IconButton, Container, Grid, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { getFilteredNewsData, deleteNewsarticlesBySlug } from '../../Services/AdminServices';
import toast, { Toaster } from 'react-hot-toast';
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer';
import parse from 'html-react-parser';
import { saveToLocalStorage } from '../../Helper/helper';

const PublishedNews = ({ ...props }) => {
  const navigate = useNavigate();
  const [newsArticles, setNewsArticles] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [deletingIds, setDeletingIds] = useState(new Set());
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedNewsSlug, setSelectedNewsSlug] = useState(null);

  useEffect(() => {
    fetchNewsArticles();
  }, []);

  const fetchNewsArticles = async () => {
    setLoading(true);
    try {
      const data = {
        categoryName: '',
        type: '',
        count: 100,
      };
      const response = await getFilteredNewsData(data);
      if (response?.data?.success && Array.isArray(response.data.latestNews)) {
        setNewsArticles(response.data.latestNews);
      } else {
        toast.error('No news articles found.');
      }
    } catch (error) {
      console.error('Error fetching news articles:', error);
      toast.error('Error fetching news articles');
    } finally {
      setLoading(false);
    }
  };

  const handleClick = () => {
    navigate('/admin/create-news');
  };

  const handleEdit = (newsSlug) => {
    navigate(`/admin/create-news/${newsSlug}`);
  };

  const handleDelete = async () => {
    // Ensure the newsSlug is valid
    if (!selectedNewsSlug) return;

    // Show loading toast
    const loadingToast = toast.loading('Deleting article...');

    try {
      const response = await deleteNewsarticlesBySlug(selectedNewsSlug);
      if (response?.data?.success) {
        // Remove the article from the state
        setNewsArticles(prevArticles => prevArticles.filter(article => article.slug !== selectedNewsSlug));
        toast.success('Article deleted successfully', { id: loadingToast });
      } else {
        toast.error('Failed to delete article', { id: loadingToast });
      }
    } catch (error) {
      toast.error('Error deleting article. Please try again.', { id: loadingToast });
    } finally {
      setOpenDialog(false); // Close the confirmation dialog
      setDeletingIds(prev => {
        const next = new Set(prev);
        next.delete(selectedNewsSlug);
        return next;
      });
    }
  };

  const handleDeleteClick = (newsSlug) => {
    setSelectedNewsSlug(newsSlug); // Set the slug of the news article to delete
    setOpenDialog(true); // Open the confirmation dialog
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog without deleting
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredNewsArticles = newsArticles.filter(
    (news) => news.title && news.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const renderFilteredContent = (htmlContent, news) => {
    let arr = [];

    parse(htmlContent, {
      replace: (domNode) => {
        arr.push(domNode);
        return null;
      },
    });

    const imageNode = arr.find((node) => node?.name === 'img');
    const image = imageNode ? (
      <Box
        component="img"
        alt="Image"
        src={imageNode?.attribs?.src || '/DefaultImages/news_default_img.jpeg'}
        sx={{
          width: '120px',
          height: '80px',
          objectFit: 'cover',
          borderRadius: '4px',
          cursor: 'pointer'
        }}
        onClick={() => {
          saveToLocalStorage(window.location.pathname, window.scrollY);
          navigate(`/user/news/details/${news?.slug}`);
        }}
      />
    ) : (
      <Box
        component="img"
        alt="Default"
        src="/DefaultImages/news_default_img.jpeg"
        sx={{
          width: '120px',
          height: '80px',
          objectFit: 'cover',
          borderRadius: '4px',
          cursor: 'pointer'
        }}
        onClick={() => {
          saveToLocalStorage(window.location.pathname, window.scrollY);
          navigate(`/user/news/details/${news?.slug}`);
        }}
      />
    );

    return { image };
  };

  return (
    <Container maxWidth="lg" sx={{ padding: 5 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <ResponsiveDrawer {...props} />
        </Grid>

        <Grid item xs={12} lg={10} sx={{ mt: 6 }}>
          <Box sx={{ mb: 2 }}>
            <Grid container alignItems="center">
              <Grid item xs sx={{ pr: 50 }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginLeft: 1}}>
                  Published News
                </Typography>
              </Grid>
              {/* <Grid item>
                <TextField
                  placeholder="Search..."
                  size="small"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  sx={{ backgroundColor: '#F4F4F4', marginRight: 1, marginLeft: 3 }}
                />
              </Grid> */}
              <Grid item>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#A56266', color: 'white' }}
                  onClick={handleClick}
                >
                  <AddIcon /> Create
                </Button>
              </Grid>
            </Grid>
          </Box>

          <TableContainer component={Box}>
            <Table sx={{ minWidth: 320 }} aria-label="news table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: '#caada7',
                      color: 'white',
                      fontWeight: 'bold',
                      width: '120px',
                    }}
                  >
                    Action
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#d18898',
                      color: 'white',
                      fontWeight: 'bold',
                      width: '40%',
                    }}
                  >
                    Title
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#A56266',
                      color: 'white',
                      fontWeight: 'bold',
                      width: '150px',
                    }}
                  >
                    Published Date
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#A56266',
                      color: 'white',
                      fontWeight: 'bold',
                      width: '150px',
                    }}
                  >
                    Image
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      Loading...
                    </TableCell>
                  </TableRow>
                ) : filteredNewsArticles.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No news articles found
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredNewsArticles.map((news, index) => {
                    const { image } = renderFilteredContent(news.content, news);
                    return (
                      <TableRow key={news._id}>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8',
                            width: '120px',
                          }}
                        >
                          <IconButton title="Edit News" onClick={() => handleEdit(news.slug)}>
                            <EditIcon style={{ fontSize: '16px' }} />
                          </IconButton>
                          <IconButton
                            title="Delete News"
                            onClick={() => handleDeleteClick(news.slug)} // Use slug here
                          >
                            <DeleteIcon style={{ fontSize: '16px' }} />
                          </IconButton>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8',
                            width: '40%',
                          }}
                        >
                          <span style={{ fontSize: 17, fontWeight: 'bold' }}>
                            {parse(news.title)}
                          </span>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8',
                            width: '150px',
                          }}
                        >
                          <span style={{ fontWeight: 600 }}>
                            {new Date(news.publishedAt).toLocaleDateString()}
                          </span>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8',
                            width: '150px',
                            padding: '8px',
                          }}
                        >
                          {image}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Delete Confirmation Dialog */}
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                Are you sure you want to delete this article?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          <Toaster position="top-center" />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PublishedNews;
