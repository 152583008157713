import React, { useState } from 'react';
import { 
  Box, 
  Button, 
  TextField, 
  Typography, 
  Container, 
  Grid, 
  Alert,
  Paper,
  IconButton,
  CircularProgress
} from '@mui/material';
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer';
import { postMagazine } from '../../Services/AdminServices';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const NewMagazine = ({ ...props }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    coverPhoto: '',
    publicationDate: '',
    pdfFile: null
  });
  
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [coverPhotoPreview, setCoverPhotoPreview] = useState('');
  const [selectedPdfName, setSelectedPdfName] = useState('');

  const validatePdfFile = (file) => {
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
    
    if (!file) {
      return 'Please select a PDF file';
    }
    
    if (file.type !== 'application/pdf') {
      return 'Only PDF files are allowed';
    }
    
    if (file.size > MAX_FILE_SIZE) {
      return 'File size must be less than 10MB';
    }
    
    return null;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCoverPhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = () => {
        setFormData({ ...formData, coverPhoto: fileReader.result });
        setCoverPhotoPreview(fileReader.result);
      };
    }
  };

  const handlePdfFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const error = validatePdfFile(file);
    if (error) {
      setErrors({ ...errors, pdfFile: error });
      toast.error(error);
      return;
    }

    // Clear any previous errors
    setErrors({ ...errors, pdfFile: null });
    setSelectedPdfName(file.name);

    const reader = new FileReader();
    reader.onload = () => {
      setFormData({ ...formData, pdfFile: file }); // Store the actual file object
    };
    reader.onerror = () => {
      const error = 'Error reading file';
      setErrors({ ...errors, pdfFile: error });
      toast.error(error);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});

    try {
      // Create FormData for multipart/form-data submission
      const submitData = new FormData();
      submitData.append('name', formData.name);
      submitData.append('publicationDate', formData.publicationDate);
      if (formData.coverPhoto) {
        submitData.append('coverPhoto', formData.coverPhoto);
      }
      if (formData.pdfFile) {
        submitData.append('pdfFile', formData.pdfFile);
      }

      const response = await postMagazine(submitData);
      toast.success(response?.data?.message);
      navigate('/admin/magazines');
    } catch (error) {
      const errorMessage = error.message || 'Error creating magazine';
      setErrors({ submit: errorMessage });
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ padding: 0 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <ResponsiveDrawer {...props} />
        </Grid>

        <Grid item xs={12} lg={10} sx={{ mt: 12 }}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
              Create New Magazine
            </Typography>

            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Magazine Name"
                variant="outlined"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
                sx={{ mb: 2 }}
              />

              {/* Cover Photo Upload */}
              <Box sx={{ mb: 2 }}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="cover-photo-upload"
                  type="file"
                  onChange={handleCoverPhotoUpload}
                />
                <label htmlFor="cover-photo-upload">
                  <Button
                    variant="outlined"
                    component="span"
                    sx={{
                      color: '#A56266',
                      borderColor: '#A56266',
                      '&:hover': {
                        borderColor: '#9b5c4e',
                        color: '#9b5c4e',
                      }
                    }}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Cover Photo
                  </Button>
                </label>

                {coverPhotoPreview && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2">Preview:</Typography>
                    <img 
                      src={coverPhotoPreview} 
                      alt="Cover Preview" 
                      style={{ maxWidth: '200px', marginTop: '10px' }} 
                    />
                  </Box>
                )}
              </Box>

              <TextField
                fullWidth
                label="Publication Date"
                variant="outlined"
                name="publicationDate"
                type="date"
                value={formData.publicationDate}
                onChange={handleInputChange}
                required
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
              />

              {/* PDF File Upload */}
              <Box sx={{ mb: 2 }}>
                <input
                  accept=".pdf"
                  style={{ display: 'none' }}
                  id="pdf-file-upload"
                  type="file"
                  onChange={handlePdfFileUpload}
                />
                <label htmlFor="pdf-file-upload">
                  <Button
                    variant="outlined"
                    component="span"
                    sx={{
                      color: '#A56266',
                      borderColor: '#A56266',
                      '&:hover': {
                        borderColor: '#9b5c4e',
                        color: '#9b5c4e',
                      }
                    }}
                    startIcon={<AttachFileIcon />}
                  >
                    Choose PDF File
                  </Button>
                </label>
                
                {selectedPdfName && (
                  <Typography 
                    variant="body2" 
                    sx={{ mt: 1, ml: 1 }}
                  >
                    Selected file: {selectedPdfName}
                  </Typography>
                )}

                {errors.pdfFile && (
                  <Alert 
                    severity="error" 
                    sx={{ mt: 1 }}
                  >
                    {errors.pdfFile}
                  </Alert>
                )}
              </Box>

              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                sx={{
                  backgroundColor: '#A56266',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#9b5c4e',
                  },
                  '&:disabled': {
                    backgroundColor: '#cccccc',
                  }
                }}
              >
                {loading ? (
                  <>
                    <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                    Creating...
                  </>
                ) : (
                  'Create Magazine'
                )}
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
};

export default NewMagazine;