import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Grid } from '@mui/material';
import ResponsiveDrawer from '../Components/Drawer/Drawer'; 
import { postCourse } from '../../Services/AdminServices';

function NewCourse(props) {
  const [courseName, setCourseName] = useState('');
  const [teacherName, setTeacherName] = useState('');
  const [dateTime, setDateTime] = useState('');
  const [googleClassroomLink, setGoogleClassroomLink] = useState('');
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the new course data
    const newCourse = {
      name: courseName,
      description,
      teacherName,
      dateTime,
      googleClassroomLink,
      image, // If backend supports image URLs, send this
    };

    try {
      // Send POST request to backend
      const response = await postCourse(newCourse);

      // Handle the response
      if (response.data.success) {
        alert("Course added successfully!");
        resetForm();  // Reset form after success
      } else {
        alert(response.data.message);  // Show error from response
      }
    } catch (error) {
      console.error("Error adding course:", error);
      alert("An error occurred while adding the course.");
    }
  };

  const resetForm = () => {
    setCourseName('');
    setTeacherName('');
    setDateTime('');
    setGoogleClassroomLink('');
    setImage(null);
    setDescription('');
  };

  return (
    <Container maxWidth="lg" sx={{ padding: 0 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <ResponsiveDrawer {...props} />
        </Grid>
        <Grid item xs={12} lg={10} sx={{ mt: 10 }}>
          <Box sx={{ padding: 4, height: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                Add Course
              </Typography>
            </div>
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" alignItems="flex-start" mt={2}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{ backgroundColor: '#A56266', color: 'white', mb: 2 }}
                >
                  Upload Image
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={(e) => setImage(URL.createObjectURL(e.target.files[0]))}
                  />
                </Button>
                {image && (
                  <Box mt={2}>
                    <img 
                      src={image} 
                      alt="Uploaded Preview" 
                      style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '8px' }} 
                    />
                  </Box>
                )}
              </Box>

              <TextField
                label="Course Name"
                fullWidth
                margin="normal"
                value={courseName}
                onChange={(e) => setCourseName(e.target.value)}
                required
                sx={{ backgroundColor: '#f3f4d3', borderColor: '#829B3E' }}
              />
              <TextField
                label="Teacher Name"
                fullWidth
                margin="normal"
                value={teacherName}
                onChange={(e) => setTeacherName(e.target.value)}
                required
                sx={{ backgroundColor: '#e0dab8', borderColor: '#829B3E' }}
              />
              <TextField
                label=""
                fullWidth
                margin="normal"
                type="datetime-local"
                value={dateTime}
                onChange={(e) => setDateTime(e.target.value)}
                required
                sx={{ backgroundColor: '#f3f4d3', borderColor: '#829B3E' }}
              />
              <TextField
                label="Google Classroom Link"
                fullWidth
                margin="normal"
                value={googleClassroomLink}
                onChange={(e) => setGoogleClassroomLink(e.target.value)}
                required
                sx={{ backgroundColor: '#e0dab8', borderColor: '#829B3E' }}
              />
              <TextField
                label="Description"
                fullWidth
                margin="normal"
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                sx={{ backgroundColor: '#f3f4d3', borderColor: '#829B3E' }}
              />
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button 
                  type="submit" 
                  variant="contained" 
                  sx={{ backgroundColor: '#A56266', color: 'white' }}
                >
                  Add Course
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default  NewCourse;
