import React from 'react';
import { Box, Button,IconButton ,Card, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from '@mui/material';
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer'; // Adjust the path as necessary
import NewspaperIcon from '@mui/icons-material/Newspaper';
import BookIcon from '@mui/icons-material/Book';
import SchoolIcon from '@mui/icons-material/School';
import WebIcon from '@mui/icons-material/Web';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import { useNavigate } from 'react-router-dom';
function Dashboard({ ...props }) {
    // Sample data for the table
    const items = [
        { id: 1, title: "Sample News", admin: "Admin 1", image: "/path/to/image1.jpg" },
        { id: 2, title: "Published Book", admin: "Admin 2", image: "/path/to/image2.jpg" },
        { id: 3, title: "Course Title", admin: "Admin 3", image: "/path/to/image3.jpg" },
        // Add more items as needed
    ];

    const navigate = useNavigate();
    const handleClick = () => {
        // Navigate to the CreateNews page
        navigate('/admin/create-news');
    };
    return (
        <Box sx={{ display: 'flex' }}>
            <ResponsiveDrawer {...props} />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.default',
                    p: 6,
                    transition: 'margin 0.3s',
                }}
            >
                <Box sx={{ mb: 5, mt: 6 }}>
                    <Card sx={{ boxShadow: 'none', borderRadius: 5 }}>
                        <Grid container spacing={3}>
                            {/* News Card */}
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ backgroundColor: "#caada7", borderRadius: 3, p: 3 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <NewspaperIcon sx={{ fontSize: 48, color: 'white', mr: 1 }} />
                                        <Typography variant="h6" sx={{ color: 'white' }}>
                                            News in this month
                                        </Typography>
                                    </Box>
                                    <Button
                                        variant="contained"
                                        startIcon={<AddCircleIcon />}
                                        sx={{
                                            textTransform: 'none',
                                            backgroundColor: 'white',
                                            color: 'black',
                                            borderRadius: 2,
                                            px: 3,
                                            py: 1
                                        }}
                                        onClick={handleClick}
                                    >
                                        Create News
                                    </Button>
                                </Box>
                            </Grid>

                            {/* Book Publishing Card */}
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ backgroundColor: "#d18898", borderRadius: 3, p: 3 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <BookIcon sx={{ fontSize: 48, color: 'white', mr: 1 }} />
                                        <Typography variant="h6" sx={{ color: 'white' }}>
                                           Published Books
                                        </Typography>
                                    </Box>
                                    <Button
                                        variant="contained"
                                        startIcon={<AddCircleIcon />}
                                        sx={{
                                            textTransform: 'none',
                                            backgroundColor: 'white',
                                            color: 'black',
                                            borderRadius: 2,
                                            px: 3,
                                            py: 1
                                        }}
                                    >
                                        Publish Book
                                    </Button>
                                </Box>
                            </Grid>

                            {/* Courses Card */}
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ backgroundColor: "#A56266", borderRadius: 3, p: 3 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <SchoolIcon sx={{ fontSize: 48, color: 'white', mr: 1 }} />
                                        <Typography variant="h6" sx={{ color: 'white' }}>
                                            Courses
                                        </Typography>
                                    </Box>
                                    <Button
                                        variant="contained"
                                        startIcon={<AddCircleIcon />}
                                        sx={{
                                            textTransform: 'none',
                                            backgroundColor: 'white',
                                            color: 'black',
                                            borderRadius: 2,
                                            px: 3,
                                            py: 1
                                        }}
                                    >
                                        Create Course
                                    </Button>
                                </Box>
                            </Grid>

                           
                        </Grid>
                    </Card>
                </Box>

                {/* Table for Created Items */}
                <Card sx={{ mb: 5, borderRadius: 5 }}>
                    <TableContainer component={Paper} elevation={3}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: "#91c27d", color: "white", fontWeight: "bold" }}>Image</TableCell>
                                    <TableCell sx={{ backgroundColor: "#5a9696", color: "white", fontWeight: "bold" }}>Title</TableCell>
                                    <TableCell sx={{ backgroundColor: "#92a65f", color: "white", fontWeight: "bold" }}>Admin</TableCell>
                                  
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.map((item,index) => (
                                    <TableRow key={item.id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                                        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                                            <img src={item.image} alt={item.title} style={{ width: 50, height: 50, borderRadius: '50%' }} />
                                            <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        marginTop: '8px',
                        marginLeft: '4px'

                      }}>
                       <IconButton  >
                          <ShareIcon style={{ fontSize: '14px' }} />
                        </IconButton>
                        <IconButton title="Edit News"  >
                          <EditIcon style={{ fontSize: '14px' }} />
                        </IconButton>
                        <IconButton title="Delete News"   >
                          <DeleteIcon style={{ fontSize: '14px' }} />
                        </IconButton>
                      </div>
                                        </TableCell>
                                        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>{item.title}</TableCell>
                                        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>{item.admin}</TableCell>
                                        
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Box>
        </Box>
    );
}

export default Dashboard;
