import React, { useEffect, useState } from 'react';
import { Box, Button, Card, FormControl, InputLabel, MenuItem, Select, TextField, Typography, Grid, Container } from '@mui/material';
import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom';
import {  getCategoryById, postCategory, updateCategory,} from '../../Services/AdminServices'; 
import toast, { Toaster } from 'react-hot-toast';
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer'; // Import ResponsiveDrawer

// Styled components for consistent look and feel
const card = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  marginBottom: 20,
  width: '70%',
  '@media (max-width: 1200px)': {
    width: '70%', // Adjust width for desktop
  },
  '@media (max-width: 992px)': {
    width: '100%', // Adjust width for tablets
  },
  '@media (max-width: 768px)': {
    width: '100%', // Adjust width for Mobiles
  },
}));

const NewCategory = () => {
  const navigate = useNavigate();
  const { catId } = useParams();
  
  // States for type and category
  const [main, setMain] = useState(""); // News or Articles
  const [category, setCategory] = useState(""); // Category input by user
  const [categoryError, setCategoryError] = useState(false);
  const [mainError, setMainError] = useState(false);

  useEffect(() => {
    if (catId) {
      getCategoryById(catId).then((res) => {
        const data = res?.data?.category;
        setMain(data?.type);
        setCategory(data?.name); // Set the category when editing
      }).catch((err) => {
        console.log("Error:", err);
      });
    }
  }, [catId]);

  const handleTypeChange = (e) => {
    setMain(e.target.value);
    setCategory(""); // Reset category input when type changes
    setMainError(false);
  };

  // Frontend: NewCategory.jsx
  const saveClick = async () => {
    // Validation
    let error = false;
    if (main === "") {
      setMainError(true);  // Fix to handle 'type' validation error
      error = true;
    }
    if (category === "") {
      setCategoryError(true);  // Fix to handle 'category' validation error
      error = true;
    }
    if (error) return;  // Prevent submission if there are errors
  
    // Prepare data with lowercase type
    const data = {
      name: category,
      type: main.toLowerCase(),  // Ensure type is in lowercase as expected by the backend
    };
  
    try {
      if (catId) {
        // If editing an existing category
        const response = await updateCategory(catId, data);
        toast.success(response?.data?.message);
        setTimeout(() => {
          navigate('/admin/categories');  // Navigate to categories list after success
        }, 2000);
      } else {
        // If creating a new category
        const response = await postCategory(data);
        toast.success(response?.data?.message);
        setTimeout(() => {
          navigate('/admin/categories');  // Navigate to categories list after success
        }, 2000);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || "An error occurred while saving the category");
    }
  };
  




  return (
    <Container maxWidth="lg" sx={{ padding: 0 }}>
      <Grid container spacing={2}>
        {/* Sidebar (Responsive Drawer) */}
        <Grid item xs={12} lg={2}>
          <ResponsiveDrawer />
        </Grid>

        {/* Main Content */}
        <Grid item xs={12} lg={10} sx={{ mt: 12 }}>
          <Box sx={{ mb: 2 }}>
            <Grid container alignItems="center">
              <Grid item xs sx={{ pr: 50 }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginLeft: 3 }}>
                  {catId ? "Edit" : "New"} Category
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* Category Form */}
          <Box component={card}>
            <Box className="section-new-category-add-information-header-flex">
              <div className='new-category-add-information-icon'></div>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {catId ? "Edit" : "Add"} Information
              </Typography>
            </Box>
            <Box className="new-category-padding">
              <FormControl fullWidth sx={{ mb: 5 }} required={true} error={mainError}>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type"
                  value={main}
                  onChange={handleTypeChange}
                >
                  <MenuItem value={"News"}>News</MenuItem>
                  <MenuItem value={"Articles"}>Articles</MenuItem>
                </Select>
              </FormControl>

              {/* Input for Category Name */}
              <TextField
                error={categoryError}
                id="category-name"
                label="Category Name"
                placeholder='Enter Category Name'
                variant="outlined"
                sx={{ mb: 5 }}
                fullWidth={true}
                required={true}
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </Box>

            <Box className="new-category-padding">
              <Button
                variant='contained'
                style={{ backgroundColor: "#A56266", color: "white", borderRadius: 10, fontSize: 15 }}
                onClick={saveClick}
              >
                {catId ? "Update" : "Save"}
              </Button>
            </Box>
          </Box>

          {/* Toast Notifications */}
          <Toaster
            position="top-center"
            reverseOrder={false}
            toastOptions={{
              success: {
                style: {
                  background: 'rgb(46, 125, 50)',
                  color: 'white'
                },
                iconTheme: {
                  primary: 'rgb(46, 125, 50)',
                  secondary: 'white',
                },
              },
              error: {
                style: {
                  background: 'rgb(211, 47, 47)',
                  color: 'white'
                },
                iconTheme: {
                  primary: 'rgb(211, 47, 47)',
                  secondary: 'white',
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewCategory;
